
import Vue from 'vue';
import {HTTP} from '@/utils/http-common';

export default Vue.extend({
  name: 'v-upload',
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    labelAlt: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Number,
      default: false,
      required: false,
    },
    edition: {
      type: Number,
      default: false,
      required: false,
    },
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 5000000,
      required: false,
    },
    confirmMessage: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      default: 'sendSingleDetailST',
    },
    availableFormats: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
    fileType: {
      type: String,
      default: 'vector',
    },
    value: {
      type: String,
      default: null,
    },
    detailId: {
      type: Number,
      default: null,
    },
  },
  data(): any {
    return {
      file: '',
      fileSelected: '',
      typeOfSelecetdFile: '',
      sizeOfSelectedFile: 0,
      loadAvailable: false,
      imageUrl: '',
      sending: false,
      disabledButton: false,
      comment: '',
      error: false,
      downloadDetailUrl: null,
      downloadDetailText: 'Wygeneruj plik oryginalny',
      fileTypes: {
        vector:
          {
            mimeTypes: ['application/postscript', 'application/illustrator', 'application/pdf'],
            errorInfo: 'PDF, EPS lub AI',
          },
        raster:
          {
            mimeTypes: ['image/jpg', 'image/jpeg', 'image/png'],
            errorInfo: 'JPG, JPEG lub PNG',
          },
        pdf:
          {
            mimeTypes: ['application/pdf'],
            errorInfo: 'PDF',
          },
      },
    };
  },
  methods: {
    handleInput(e: any) {
      this.file = this.$refs.file.files[0];

      if (this.file) {
        this.typeOfSelecetdFile = this.file.type;
        this.sizeOfSelectedFile = this.file.size;
      }

      if (this.file && this.fileTypes[this.fileType].mimeTypes.includes(this.file.type) && this.file.size < this.maxSize) {
        this.fileSelected = e.target.value.split('\\').pop();
        this.loadAvailable = true;
        this.error = false;
      } else {
        // tslint:disable-next-line
        this.fileSelected = `Wybierz plik o maksymalnej wielkości ${ this.maxSize / 1000000 } MB w formacie ${this.fileTypes[this.fileType].errorInfo}`;
        this.error = true;
      }
    },
    sendFile(e: any) {
      let formData;
      if (this.endpoint === 'sendCompanyData') {
        formData = {
          logo: this.file,
        };
      } else {
        formData = {
          file: this.file,
          key: this.name,
          confirmed: 0,
        };
      }

      this.sending = true;
      this.disabledButton = true;
      this.imageUrl = '';
      this.comment = 'Prosimy o cierpliwość, Twój plik jest właśnie przetwarzany…';

      this.$store.dispatch(this.endpoint, formData).then((response: any) => {
        this.ongoingState = false;
        if (response) {
          this.sending = false;
          this.disabledButton = false;
          this.comment = '';
          this.error = false;

          if (this.endpoint === 'sendCompanyData') {
            this.imageUrl = response.data.data.logo;
          } else {
            this.imageUrl = response.data.data.value;
          }
          this.$emit('input', this.imageUrl);
          this.fileSelected = '';
          this.loadAvailable = false;
        } else {
          this.sending = false;
          this.disabledButton = false;
          this.comment = 'Wystąpił błąd podczas wysyłania pliku.';
        }
      }).catch((error: any) => {
        this.sending = false;
        this.error = true;
        this.disabledButton = false;
        this.comment = 'Wystąpił błąd podczas wysyłania pliku.';
      });
    },
    clearInput() {
      const inpt: any = document.getElementById(this.id);
      inpt.value = '';
      this.fileSelected = '';
      this.loadAvailable = false;
      this.error = false;
      this.imageUrl = '';
      this.file = '',
      this.fileSelected = '',
      this.$emit('clear-input', this.id);
      this.sendFile();
    },
    downloadDetail(e: any) {
      if (this.detailId) {
        if (this.downloadDetailUrl === null) {
          e.preventDefault();
          HTTP.get(`api/detail/export/${this.edition}/${this.detailId}`).then((response: any) => {
            this.downloadDetailUrl = response.data.data.message;
            this.downloadDetailText = 'Pobierz plik';
          })
          .catch((error: any) => {
            console.error(error);
          });
        }
      }
    },
  },
  mounted() {
    if (this.value) {
      this.imageUrl = this.value;
    }
    this.disabledButton = this.disabled;
  },
  watch: {
    value() {
      this.imageUrl = this.value;
    },
    disabled() {
      this.disabledButton = this.disabled;
    },
    file() {
      if (this.file === undefined) {
        this.file = '';
        this.fileSelected = '';
        this.typeOfSelecetdFile = '';
        this.sizeOfSelectedFile = 0;
        this.loadAvailable = false;
        this.error = false;
      }
    },
  },
});
